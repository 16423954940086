import React, { useEffect, useState } from "react";
import style from "./css/FormAddLink.module.css";
import Api from "../Services/Api";

export default function FormAddLink({
  dataEvents,
  formAddVisible,
  setFormAddVisible,
}) {
  const dataScreen = [
    {
      name: "Perfil",
      value: "ProfileScreen",
    },
    {
      name: "Inicio",
      value: "HomeScreen",
    },
    {
      name: "Favoritos",
      value: "FavouriteScreen",
    },
    {
      name: "Puntuaciones",
      value: "ScoreScreen", 
    }
  ];
  const [countries, setCountries] = useState([]);
  const [form, setForm] = useState({
    name: "",
    url: "",
    icon: {
      file: "",
      name: "",
    },
    type: "1",
    section: 1,
    visible: 1,
    event: 1,
  });

  const [files, setFiles] = useState({
    file: "",
    name: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.icon = files;
    const response = await Api.createLinkExternal(form);
    if (response) {
      setFormAddVisible(false);
      window.location.reload();
    } else {
      alert("Error al guardar");
    }
  };

  if (form.type === "1" && form.url === "") {
    form.url = "ScoreScreen";
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      convertToBase64(file).then((base64) => {
        setFiles({ file: base64, name: file.name });
      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error al convertir el archivo:", error);
        reject(error);
      };
    });
  };

  const getCountries = async () => {
    let response = await Api.adminCountries();
    setCountries(response);
  };

  useEffect(() => {
    getCountries();
    setForm({
      name: "",
      url: "",
      icon: {
        file: "",
        name: "",
      },
      type: "1",
      section: 1,
      visible: 1,
      event: 1,
      country_id: 1,
    });
    setFiles({
      file: "",
      name: "",
    });
  }, [formAddVisible]);

  return (
    <>
      {formAddVisible && (
        <div
          className={style.modalOverlay}
          onClick={() => setFormAddVisible(false)}
        >
          <div
            className={style.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className={style.close}
              onClick={() => setFormAddVisible(false)}
            >
              &times;
            </span>
            <h2>Agregar enlace</h2>
            <div className="form-group">
              <label htmlFor="type">Tipo</label>
              <select
                className="form-control"
                id="type"
                value={form.type}
                onChange={handleChange}
              >
                <option value={1}>Interno</option>
                <option value={2}>Externo</option>
                <option value={3}>Interno App</option>
                <option value={4}>Evento</option>
              </select>
            </div>
            {form.type !== "1" && (
              <div className="form-group">
                <label htmlFor="url">URL</label>
                <input
                  value={form.url}
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="url"
                />
              </div>
            )}
            {form.type === "1" && (
              <div className="form-group">
                <label htmlFor="url">URL</label>
                <select
                  className="form-control"
                  id="url"
                  value={form.url}
                  onChange={handleChange}
                >
                  {dataScreen.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                value={form.name}
                onChange={handleChange}
                type="text"
                className="form-control"
                id="name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="icon">Icon</label>
              <div>
                <input
                  onChange={handleFileChange}
                  style={{ marginLeft: "15px" }}
                  type="file"
                />
                {files.file && (
                  <img
                    src={files.file}
                    alt={files.name}
                    style={{
                      borderRadius: "15px",
                      marginTop: "15px",
                      width: "50%",
                      height: "50%",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="section">Seccion</label>
              <select
                className="form-control"
                id="section"
                value={form.section}
                onChange={handleChange}
              >
                <option value={1}>Evento</option>
                <option value={2}>Menu</option>
                <option value={3}>Home Seccion 1</option>
                <option value={4}>Home Seccion 2</option>
                <option value={5}>Home Seccion 3 (Evento)</option>
                <option value={6}>Home Seccion 4</option>
                <option value={7}>Home Seccion 5</option>
                <option value={8}>Puntuaciones Seccion</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="event">Evento</label>
              <select
                className="form-control"
                id="event"
                value={form.event}
                onChange={handleChange}
              >
                {dataEvents.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="visible">Estado</label>
              <select
                className="form-control"
                id="visible"
                value={form.status}
                onChange={handleChange}
              >
                <option value={1}>Activo</option>
                <option value={0}>Inactivo</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="country">País</label>
              <select
                className="form-control"
                id="country_id"
                value={form.country_id}
                onChange={handleChange}
              >
                {countries.length > 0 && countries.map((country, index) => (
                  <option key={index} value={country.id}>
                    {country.title}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
